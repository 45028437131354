.bg-blue {
	background-color: #009aff !important;
}

.text-red{
	color: red;
}

.baseBtn {
	background-color: #092143;
	color: white;
	border: none;
	box-shadow: 1px 1px 3px 0px #707070;
	height: 50px;
	font-weight: 600;
}

.smallBtn {
	color: white !important;
	border: none !important;
	box-shadow: 1px 1px 3px 0px #707070 !important;
	height: 50px !important;
}
.openBtn{
		background-color: #092143;
	color: white;
	border: none;
	box-shadow: 1px 1px 3px 0px #707070;
	height: 30px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 600;

}



input {
	background-color: #eeeeee !important;
	border-radius: unset !important;
	border: none !important;
	height: 50px !important;
}

select {
	background-color: #eeeeee !important;
	border-radius: unset !important;
	border: none !important;
	height: 50px !important;
}
.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.inputGroup {
	background-color: #eeeeee !important;
	border-radius: unset !important;
	border: none !important;
	height: 50px !important;
}

.textarea {
	background-color: #eeeeee !important;
	border-radius: unset !important;
	border: none !important;
}

select {
	background-color: #eeeeee !important;
	border-radius: unset !important;
	border: none !important;
	height: 50px !important;
}

.pointer {
	cursor: pointer;
}

.form-control:focus {
	box-shadow: 0 0 0 0.09rem rgb(95 166 243) !important;
}

.centerPage {
	padding: 25px;
	position: fixed;
	bottom: 0px;
	top: 0px;
	right: 0px;
	left: 240px;
	overflow-x: hidden;
	overflow-y: auto;
}

#wrapper {
	overflow-x: hidden;
	overflow-y: auto;
}
.fs-10{
	font-size: 10px;
	color: gray;
}
@media (max-width: 768px) {
	.centerPage {
		padding: 25px;
		position: initial;
		bottom: 0px;
		top: 0px;
		right: 0px;
		left: 240px;
		overflow-x: hidden;
		overflow-y: auto;
	}
}

ul {
	list-style: none;
	padding: 0;
}

li {
	padding-left: 1.3em;
}

li:before {
	display: inline-block;
	margin-left: -1.3em; /* same as padding-left set on li */
	width: 1.3em; /* same as padding-left set on li */
}

.pointer {
	cursor: pointer;
}

.colorText {
	color: #0093b2 !important;
}

.colorItem {
	background: #0093b2 !important;
}

.colorItemDarker {
	background: #23333b !important;
}

.colorItemLigther {
	background: #00b3d9 !important;
}

.color-white{
	color: #eeeeee !important;
}


/* datepicker */
.react-datepicker-popper {
	z-index: 9999 !important;
}

.react-datepicker__input-container {
	width: inherit;
}

.react-datepicker-wrapper {
	width: 100%;
}

.badge-blue-color {
	background-color: #092143 !important;
}
.rem{
 padding: 0.4rem !important;
}

.ml-60{
	margin-left: 60px;
}

.flex-evenly{
	display: flex !important;
	flex-direction: row !important;
	justify-content: space-evenly !important;
	align-items: center !important;
	flex-wrap: wrap;
}
.folder-name {
	max-width: 100px;
	/* Ajusta según el tamaño máximo que desees */
	white-space: nowrap;
	/* Evita que el texto se divida en varias líneas */
	overflow: hidden;
	/* Oculta el desbordamiento del texto */
	text-overflow: ellipsis;
	/* Añade "..." cuando el texto es demasiado largo */
	text-align: center;
	/* Centra el texto */
}
.flex-center{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.p-20{
	padding: 20px;
}

.m-20{
	margin: 20px !important;
}

.ml-10{
	margin-left: 10px !important;
}

.flex-col-center{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center	;
}
.w-max{
	width: max-content !important;
}

.btnMargin{
	margin: 5px;
    margin-top: 20%;
}

.greenBtn{
	background-color: green;
	border-radius: 5px;
		color: white;
	font-weight: bold;
}

.greenBtn:hover{
	cursor: pointer;
	 box-shadow: 0px 4px 8px rgba(1, 1, 1, 0.8) !important;	
	 scale: 1.2 !important;
	 transition-duration: 1s;
	
}

.redBtn{
	background-color: red;
	border-radius: 5px;
	color: white;
	font-weight: bold;
}

.redBtn:hover{
	cursor: pointer;
	 box-shadow: 0px 4px 8px rgba(1, 1, 1, 0.8) !important;	
	 scale: 1.2 !important;
	 transition-duration: 1s;
	
}

.blueBtn{
	background-color: #092143;
	border-radius: 5px;
	color: white;
	font-weight: bold;
}

.blueBtn:hover{
	cursor: pointer;
	 box-shadow: 0px 4px 8px rgba(1, 1, 1, 0.8) !important;	
	 scale: 1.2 !important;
	 transition-duration: 1s;
	
}